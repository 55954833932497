import React, { useEffect, useState } from "react";

import { Col, Input, InputGroup, Label, Row } from "reactstrap";

import withRouter from "../../hooks/withRouter";
import { withTranslation } from "react-i18next";

import PropTypes from "prop-types";
import { postWithoutToken } from "../../helpers/axios_with_headers.js";
import { phoneOptionsTurkish } from "../../constants/phoneCodesTurkish";
import ReCAPTCHA from "react-google-recaptcha";
import Select from "react-select";

// import images

const WebForm = props => {
  const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY
  //meta title
  document.title = "Web Form";

  const [fullname, setFullname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneCode, setPhoneCode] = useState();
  const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
  const [disableButton, setIsDisableButton] = useState(true);

  const recaptchaRef = React.createRef();

  const handleChange = (e) => {
    switch (e.target.name) {
      case "fullname":
        setFullname(e.target.value);
        break;
    }
  };

  const onChangePhoneCode = (e) => {
    setPhoneCode({ label: e.label, value: e.value });
  };

  const handleChangePhone = (e) => {
    let str = e.target.value.toString();
    let phoneCode = "";
    let tel = "";
    if (str.includes("+")) {
      phoneCode = str.split(/\s/)[0];
      findPhoneCode(phoneCode);
      tel = str.split(phoneCode)[1].replaceAll(/\s+/g, "");
    } else {
      tel = str.replaceAll(/\s+/g, "");
    }
    setPhoneNumber(tel);
  };

  const findPhoneCode = (code) => {
    phoneOptionsTurkish.forEach(cd => {
      if (cd.value === code)
        setPhoneCode(cd);
    });
  };

  useEffect(() => {
    const dis = isCaptchaSuccessful && phoneCode && phoneCode.value && fullname.length > 3 && phoneNumber !== "";
    setIsDisableButton(!dis);
  }, [isCaptchaSuccessful, fullname, phoneNumber]);

  const sendFormDataToBackend = () => {
    const createLeadReq = {
      secretKey: "CN}QSa1nn%KNo?.qu?w+qFNyoPk')hE'",
      createLeads: [{
        fullName: fullname,
        firstName: fullname.split(" ")[0],
        lastName: fullname.split(" ")[1],
        phoneNumber: phoneCode.value + phoneNumber
      }]
    };

    postWithoutToken(process.env.REACT_APP_API_URL + "/leadIntegration/receiveFromWebsite", createLeadReq).then(r => {
      if (r.status === 200) {
        setPhoneNumber("");
        setFullname("");
        setPhoneCode("");
        setIsCaptchaSuccess(false);
        setIsDisableButton(true);
      }
    });
    recaptchaRef.current.reset();
  };

  function onCaptchaChange(value) {
    setIsCaptchaSuccess(true);
  }

  return (
    <div className="page-content-full justify-content-center">
      <div className="vertical-centered-div">
        <Col xl={12} md={12} xs={12}>
          <Row className="m-2">
            <Col className="col-12">
              <div className="mb-2">
                <Label className="form-label">{props.t("İsim Soyisim")}</Label>
                <Input
                  id="fullname"
                  name="fullname"
                  type="text"
                  onChange={handleChange}
                  value={fullname || ""}
                  invalid={!fullname}
                  required={true}
                />
              </div>
              <div className="mb-2">
                <Label className="form-label">{props.t("Telefon")}</Label>
                <InputGroup>
                  <Col xs={5} md={5} xl={5}>
                    <Select
                      id="phoneCode"
                      className="bs-select"
                      value={phoneCode}
                      onChange={onChangePhoneCode}
                      options={phoneOptionsTurkish}
                    />
                  </Col>
                  <Col xs={7} md={7} xl={7}>
                    <Input
                      id="phoneNum"
                      name="phoneNumber"
                      type="text"
                      onChange={handleChangePhone}
                      value={phoneNumber || ""}
                      required={true}
                      placeholder="--- --- ----"
                      invalid={!phoneNumber}
                    />
                  </Col>
                </InputGroup>
              </div>
            </Col>
          </Row>
          <Row className="m-2">
            <Col className="col"></Col>
            <Col className="col">
              <ReCAPTCHA
                ref={recaptchaRef}
                name="recaptcha"
                id="recaptcha"
                sitekey={siteKey}
                onChange={onCaptchaChange}
                onExpired={() => {
                  recaptchaRef.current.reset(); // here
                }}
                theme="light"
              />
            </Col>
            <Col className="col"></Col>
          </Row>
          <Row className="m-2">
            <Col>
              <div className="text-end mt-2">
                <button
                  disabled={disableButton}
                  id="save"
                  type="submit"
                  className="btn btn-success"
                  onClick={() => sendFormDataToBackend()}
                >
                  {props.t("Kaydet")}
                </button>
              </div>
            </Col>
          </Row>
        </Col>
      </div>
    </div>
  );
};

WebForm.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(WebForm));