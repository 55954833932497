import React, { useEffect } from "react";
import gtag from "ga-gtag";

const FormSuccessTr = () => {

  function sendFormDataToBackendGtag() {
    gtag("event", "form-success", { "send_to": "AW-11371194371/XkBCCO3n0ewYEIPQmq4q" });
  }

  useEffect(() => {
    sendFormDataToBackendGtag();
  }, []);

  return (
    <div className="page-content-full justify-content-center">
      <div className="vertical-centered-div">
        <p className="text-sm-center">
          <i className="mdi mdi-check-bold text-success display-1" />
          <p className="text-info font-size-24 fw-bold">Teşekkürler</p>
          <p className="text-dark font-size-16 fw-bold mt-2">Başvurunuzu aldık.</p>
          <p className="text-secondary font-size-8 mt-0">Mümkün olan en kısa sürede sizlere geri dönüş sağlayacağız.</p>
        </p>
      </div>
    </div>
  );
};

export default FormSuccessTr;