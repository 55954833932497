import React, {useEffect, useState} from "react";

import {Col, Input, InputGroup, Label, Row} from "reactstrap";

import withRouter from "../../hooks/withRouter"
import {withTranslation} from "react-i18next";

import PropTypes from "prop-types";
import {postWithoutToken} from "../../helpers/axios_with_headers.js";
import ReCAPTCHA from "react-google-recaptcha";
import {phoneOptions} from "../../constants/phoneCodes";
import {useNavigate} from "react-router-dom";
import gtag from "ga-gtag";

// import images

const WebForm = props => {
    const siteKey = process.env.REACT_APP_CAPTCHA_SITE_KEY;
    //meta title
    document.title = "Web Form";

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [phoneCode, setPhoneCode] = useState(phoneOptions[0]);
    const [isCaptchaSuccessful, setIsCaptchaSuccess] = useState(false);
    const [disableButton, setIsDisableButton] = useState(true);
    const [isSent, setIsSent] = useState(false);
    const [isContractChecked, setIsContractChecked] = useState(false);

    const recaptchaRef = React.createRef();

    const handleChange = (e) => {
        switch (e.target.name) {
            case "email":
                setEmail(e.target.value);
                break;
            case "firstName":
                setFirstName(e.target.value);
                break;
            case "lastName":
                setLastName(e.target.value);
                break;
        }
    };

    const onChangePhoneCode = (e) => {
        setPhoneCode({label: e.target.label, value: e.target.value});
    };

    const handleChangePhone = (e) => {
        let str = e.target.value.toString();
        let phoneCode = "";
        let tel = "";
        if (str.includes("+")) {
            phoneCode = str.split(/\s/)[0];
            findPhoneCode(phoneCode);
            tel = str.split(phoneCode)[1].replaceAll(/\s+/g, "");
        } else {
            tel = str.replaceAll(/\s+/g, "");
        }
        setPhoneNumber(tel);
    };

    const findPhoneCode = (code) => {
        phoneOptions.forEach(cd => {
            if (cd.value === code)
                setPhoneCode(cd);
        });
    };

    useEffect(() => {
        const dis = isCaptchaSuccessful && firstName !== "" && lastName !== "" && phoneNumber !== "" && email !== "" && isContractChecked;
        setIsDisableButton(!dis);
    }, [isCaptchaSuccessful, firstName, lastName, phoneNumber, email, isContractChecked]);

    const sendFormDataToBackend = () => {
        const createLeadReq = {
            secretKey: "CN}QSa1nn%KNo?.qu?w+qFNyoPk')hE'",
            createLeads: [{
                email: email,
                fullName: firstName + " " + lastName,
                firstName: firstName,
                lastName: lastName,
                phoneNumber: phoneCode.value + phoneNumber
            }]
        };

        postWithoutToken(process.env.REACT_APP_API_URL + "/leadIntegration/receiveFromWebsite", createLeadReq).then(r => {
            if (r.status === 200) {
                setIsSent(true);
                setPhoneNumber("");
                setFirstName("");
                setLastName("");
                setEmail("");
                setPhoneCode(phoneOptions[0]);
                setIsCaptchaSuccess(false);
                setIsDisableButton(true);
                recaptchaRef.current.reset();
            }
        });
    };

    function onCaptchaChange(value) {
        setIsCaptchaSuccess(true);
    }

    function sendFormDataToBackendGtag() {
        gtag('event', 'conversion', {'send_to': 'AW-11371194371/XkBCCO3n0ewYEIPQmq4q'});
    }

    useEffect(() => {
        if (isSent) {
            navigate("/form-success/de");
        }
    }, [isSent])

    return (
        <div className="page-content-full justify-content-center">
            <div className="vertical-centered-div">
                <Col xl={12} md={12} xs={12}>
                    <Row className="m-2">
                        <Col className="col-12">
                            <div className="mb-2">
                                <div className="row">
                                    <div className="col-6">
                                        <Label className="form-label">{props.t("Name")}</Label>
                                        <Input
                                            id="firstName"
                                            name="firstName"
                                            type="text"
                                            onChange={handleChange}
                                            value={firstName || ""}
                                            invalid={!firstName}
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <Label className="form-label">{props.t("Nachname")}</Label>
                                        <Input
                                            id="lastName"
                                            name="lastName"
                                            type="text"
                                            onChange={handleChange}
                                            value={lastName || ""}
                                            invalid={!lastName}
                                            required={true}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="mb-2">
                                <Label className="form-label">{props.t("Telefonnummer")}</Label>
                                <InputGroup>
                                    <select
                                        id="phoneCode"
                                        className="form-select"
                                        value={phoneCode.value}
                                        onChange={onChangePhoneCode}
                                    >
                                        {phoneOptions.map(code => (
                                            <option id={code.value} key={code.label} value={code.value}>
                                                {code.label}
                                            </option>
                                        ))
                                        }
                                    </select>
                                    <Input
                                        id="phoneNum"
                                        name="phoneNumber"
                                        type="text"
                                        onChange={handleChangePhone}
                                        value={phoneNumber || ""}
                                        required={true}
                                        placeholder="--- --- ----"
                                        invalid={!phoneNumber}
                                    />
                                </InputGroup>
                            </div>
                            <div className="mb-2">
                                <Label className="form-label">{props.t("Email")}</Label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    onChange={handleChange}
                                    value={email}
                                    required={false}
                                    invalid={email.length === 0}
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row className="m-2">
                        <div className="border border-1">
                            <Row className="m-4 justify-content-center">
                                <div className="col-3 text-md-end">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        id="contractCheck"
                                        checked={isContractChecked}
                                        onChange={() => {
                                            setIsContractChecked(!isContractChecked);
                                        }}
                                    />
                                </div>
                                <div className="col-9">
                                    Ich stimme den <a
                                    href="https://meduluxhealth.com/de/wp-content/uploads/sites/3/2024/02/Terms-And-Conditions-DE-1-.pdf"
                                    target="_blank" rel="noreferrer"
                                    download><span className="fw-bold">Bedingungen und Konditionen</span></a> zu.
                                </div>
                            </Row>
                        </div>
                    </Row>
                    <Row className="m-2">
                        <Col className="col"></Col>
                        <Col className="col">
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                name="recaptcha"
                                id="recaptcha"
                                sitekey={siteKey}
                                onChange={onCaptchaChange}
                                onExpired={() => {
                                    recaptchaRef.current.reset(); // here
                                }}
                                theme="light"
                            />
                        </Col>
                        <Col className="col"></Col>
                    </Row>
                    <Row className="m-2">
                        <Col>
                            <div className="text-end mt-2">
                                <button
                                    disabled={disableButton}
                                    id="save"
                                    type="submit"
                                    className="btn btn-success"
                                    onClick={() => {
                                        sendFormDataToBackend(), sendFormDataToBackendGtag();
                                    }}
                                >
                                    {props.t("Speichern")}
                                </button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </div>
        </div>
    );
};

WebForm.propTypes = {
    t: PropTypes.any
};

export default withRouter(withTranslation()(WebForm));